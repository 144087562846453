import { Component, Inject } from '@angular/core';
import { Algo } from '../shared/algo';
import { algos } from '../shared/algos';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent {

  public items: Algo[];

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.items = algos.filter(algo => algo.type === 'Indicator');
  }

  public download(fileName: string) {
    const link = this.document.createElement('a');
    link.href = `/assets/downloads/${fileName}`;
    link.target = '_blank';
    link.click();
  }
}
