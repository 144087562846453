<section class="indicators-container">

  <p-dataView #dv [value]="items" layout='grid' filterBy='name'>
    <p-header>Indicators <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value, 'contains')"></p-header>
    <ng-template let-item pTemplate="gridItem" >
      <div class="ui-g-12 ui-md-3">
        <p-card
          header="{{ item.name }}"
          [style]="{ width: '400px', height: '500px', margin: '2em' }"
          styleClass="ui-card-shadow"
        >
          <p-header>
            <img src="{{ item.imageUrl }}" alt="{{ item.name }}" />
          </p-header>
          {{ item.shortDescription }}
          <p-footer>
            <p-button
              label="More..."
              icon="pi pi-eye"
              iconPos="left"
              routerLink="{{ item.route }}"
            ></p-button>&nbsp;
            <p-button label="Download" icon="pi pi-download" iconPos='left' (onClick)='download(item.fileName)'></p-button>
          </p-footer>
        </p-card>
      </div>
    </ng-template>
  </p-dataView>
</section>
