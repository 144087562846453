import { Algo } from '../algo';

export const plAlgo: Algo = {
  id: 'pl',
  route: '/viewer/pl',
  name: 'Price Levels',
  fileName: 'ctk Price Levels.algo',
  imageUrl: '/assets/images/pl.png',
  shortDescription:
    'Draws lines every 10 pips with options for showing significant levels in stronger colours',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
  description: `Prices that end in round numbers are considered significant many traders pay attention to them.  This indicator draws lines at round number
  levels providing the option to use different colours for some levels (00, 20, 50 and 80).
  On charts where the timeframe is greater than 1 hour only 50 and 00 levels are drawn as lines are too close together at this scale`,
  parameters: [
    {
      default: 'LightGray',
      description:
        'The colour for levels that do not have their own colour defined',
      name: 'Base Level Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Red',
      description: 'The colour of lines for levels ending in 00',
      name: 'Level 00 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Yellow',
      description: 'The colour of lines for levels ending in 20',
      name: 'Level 20 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Orange',
      description: 'The colour of lines for levels ending in 50',
      name: 'Level 50 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Yellow',
      description: 'The colour of lines for levels ending in 80',
      name: 'Level 80 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'DotsRare',
      description: 'The style for all lines',
      name: 'Level 00 Colour',
      section: 'Line Customisation',
      type: 'List',
    },
    {
      default: '1',
      description: 'The thickness for lines',
      name: 'Line Thickness',
      section: 'Line Customisation',
      type: 'Integer',
    },
    {
      default: '50',
      description: 'The number of levels to draw lines for, starting from the current price',
      name: 'Levels to Draw (Above/Below)',
      section: 'Configuration',
      type: 'Integer'
    }
  ],
};
