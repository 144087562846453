

import { Algo } from '../algo';
import { ctkitAlgo } from './ctkit';
import { csiAlgo } from './csi';
import { cshAlgo } from './csh';
import { ffcAlgo } from './ffc';
import { haAlgo } from './ha';
import { hamaAlgo } from './hama';
import { pslAlgo } from './psl';
import { plAlgo } from './pl';
import { ppAlgo } from './pp';

export const algos: Algo[] = [
  ctkitAlgo,
  csiAlgo,
  cshAlgo,
  ffcAlgo,
  haAlgo,
  hamaAlgo,
  pslAlgo,
  plAlgo,
  ppAlgo
];
