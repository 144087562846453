// tslint:disable: max-line-length
import { Algo } from '../algo';

export const csiAlgo: Algo = {
    id: 'csi',
    route: '/viewer/csi',
    name: 'Currency Strength Indicator',
    fileName: 'ctk Currency Strength Indicator.algo',
    imageUrl: '/assets/images/csi.png',
    shortDescription:
      'Identify strenth and weakness of 8 major currencies via a line chart',
    type: 'Indicator',
    ctVersion: '3.7',
    version: '1.1',
    parameters: [
      {
        section: 'Configuration',
        name: 'Periods',
        type: 'Integer',
        description:
          'The number of periods(or bars) to use for calculating strength',
        default: '20',
      },
      {
        section: 'Configuration',
        name: 'Use Geometric Avg.',
        type: 'Boolean',
        description:
          'Indicates whether to use the alternative algorithm for calculating strength',
        default: 'False',
      },
      {
        section: 'Configuration',
        name: 'Update Frequency Seconds',
        type: 'Integer',
        description: 'The number of seconds between updates',
        default: '2',
      },
    ],
    description: `<p>The Currency Strength Indicator allows you to quickly identify strong and weak currencies helping you decide what to trade.
                  It subscribes to the data feed for the 28 currency pairs that are derived from the 8 major currencies.
                  It derives the relatvie strength of each individual currency by calculating the average percentage move against the other 7 and plots this as a series of lines that revolve around zero.
                  Two algorithms are supported for calculating relative strength. The default is a simple average of percentage moves. The other uses a Geometric Average of the percentage moves.
                  A currency is strong when it's line is above zero and weak when below.
                  A currency pair has momentum when there is strong divergence over the most recent periods.
                  The timeframe of the host chart is used and the data for the host chart is used if it is one of the 28 pairs monitored
                  The indicator is updated based on the Update Frequency Seconds parameter, allowing you to tune the impact on performance.`,
  };
