import { Algo } from '../algo';

export const ppAlgo: Algo = {
  id: 'pp',
  route: '/viewer/pp',
  name: 'Pivot Points - Multi Formula',
  fileName: 'ctk Pivot Points.algo',
  imageUrl: '/assets/images/pp.png',
  shortDescription:
    'Renders pivot points using 1 of 4 formulas',
  type: 'Indicator',
  ctVersion: '3.8',
  version: '1.1.1',
  description: `Pivot points often act as strong support or resistance and many traders use them.  This indicator renders pivot points in 1 of 4 forumulas
              in commonn use Standard, Camarilla, Woodie and Fibonacci.  Additionally it renders lines at the current days Open and previous days Hi and Lo as these are also
              known to provide support and resistance.  The open sometimes acts as a magnet pulling price back if it gets too far away.`,
  parameters: [
    {
      default: 'Standard',
      description: 'The formula to use for calculating pivot points',
      name: 'Formula',
      section: 'Configuration',
      type: 'List'
    },
    {
      default: 'Blue',
      description:
        'The colour for main pivot point',
      name: 'Pivot Point Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Green',
      description: 'The colour for level 1 S/R lines',
      name: 'R1/S1 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Orange',
      description: 'The colour for level 2 S/R lines',
      name: 'R2/S2 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Red',
      description: 'The colour for level 3 S/R lines',
      name: 'R3/S3 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Violet',
      description: 'The colour for level 4 S/R lines (Camarilla formula only)',
      name: 'R4/S4 Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Magenta',
      description: 'The colour for the Open line',
      name: 'Open Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'LimeGreen',
      description: 'The colour for the HI line',
      name: 'Hi Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'Salmon',
      description: 'The colour for the LO line',
      name: 'Lo Colour',
      section: 'Colours',
      type: 'List',
    },
    {
      default: 'DotsRare',
      description: 'The style for all lines',
      name: 'Line Style',
      section: 'Line Customisation',
      type: 'List',
    },
    {
      default: '1',
      description: 'The thickness for all lines',
      name: 'Line Thickness',
      section: 'Line Customisation',
      type: 'Integer',
    }
  ],
};
