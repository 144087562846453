import { Algo } from '../algo';

// tslint:disable: max-line-length
export const ffcAlgo: Algo = {
  id: 'ffc',
  route: '/viewer/ffc',
  name: 'Forex Factory Calendar',
  fileName: 'ctk FF Calendar.algo',
  imageUrl: '/assets/images/ffc.png',
  shortDescription:
    'List of upcoming events from Forex Factory at your fingertips',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
  description: `This indicator shows upcoming financial events using the RSS Calendar feed from Forex Factory.  It displays in a separate Indicator
              Window.  Events are removed once they are in the past after a delay that you can configure.  You also have full control over how
              often the calendar is updated.`,
  parameters: [
    {
      default: '20',
      description:
        'The frequency in minutes that the news will be refreshed.  Events can sometimes be added or removed during the day, so we check based on this setting.',
      name: 'Refresh Interval (Minutes)',
      section: '',
      type: 'Integer',
    },
    {
      default: '30',
      description:
        'The length of time in minutes that an event will be displayed for after it has expired.',
      name: 'Display ForMinutes',
      section: '',
      type: 'Integer',
    }
  ]
};
