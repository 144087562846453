import { Algo } from '../algo';

export const haAlgo: Algo = {
  id: 'ha',
  route: '/viewer/ha',
  name: 'Heikin Ashi Candles',
  fileName: 'ctk Heiken Ashi.algo',
  imageUrl: '/assets/images/ha.png',
  shortDescription: 'Paints Heiken Ashi candles on the host chart',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
  description: `This indicator paints candles on the host chart using Heiken Ashi calculations.  It can optionally replace the default candles or
                it can overlay them.  In the latter case you would probably want to use different colours to distinquish the different candles. The
                indicator responds to changes in the zoom level of the chart and repaints to adjust candle sizes.`,
  parameters: [
    {
      default: 'Yes',
      description: `Indicates whether to hide the default rendering of the host chart.
                    If Yes the outline and fill colours are changed to match the background
                    and you will need to reset these if you remove the indicator.`,
      name: 'Overwrite Current Chart',
      type: 'Boolean',
      section: 'Configuration',
    },
    {
      default: 'Green',
      description: 'Colour to use for bullish candles',
      name: 'Bullish Colour',
      type: 'List',
      section: 'Colours',
    },
    {
      default: 'Red',
      description: 'Colour to use for bearish candles',
      name: 'Bearish Colour',
      type: 'List',
      section: 'Colours',
    }
  ],
};
