<section class='container'>
  <h1>cTrader Kit</h1>
  <h4>Optimise your trading with professionally developed tools from cTrader Kit</h4>
<p-carousel
  [value]="items"
  [numVisible]="3"
  [numScroll]="1"
  [responsiveOptions]="responsiveOptions"
  [autoplayInterval]="5000"
  [circular]="true"
>
  <ng-template let-item pTemplate="item">
    <p-card
      header="{{ item.name }}"
      [style]="{ width: '400px', height: '500px', margin: '2em' }"
      styleClass="ui-card-shadow"
    >
      <p-header>
        <img src="{{ item.imageUrl }}" alt="{{ item.name }}" />
      </p-header>
      {{ item.shortDescription }}
      <p-footer>
        <p-button label="More..." icon="pi pi-eye" iconPos="left" routerLink='{{item.route}}'></p-button>
      </p-footer>
    </p-card>
  </ng-template>
</p-carousel>
</section>
