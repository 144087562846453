export const environment = {
  production: false,
  firebase: {
    apiKey : 'AIzaSyB18NlM_dGKNQhL7NC3dr_eM4_w64JMviA',
    authDomain : 'ctkit-e1664.firebaseapp.com',
    databaseURL : 'https://ctkit-e1664.firebaseio.com',
    storageBucket : 'ctkit-e1664.appspot.com',
    messagingSenderId : '659935172552'
  }
};
