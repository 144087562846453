import { Algo } from '../algo';

export const hamaAlgo: Algo = {
  id: 'hama',
  route: '/viewer/hama',
  name: 'Heikin Ashi Moving Average',
  fileName: 'ctk Heiken Ashi Moving Average.algo',
  imageUrl: '/assets/images/hama.png',
  shortDescription: 'A moving average using Heikin Ashi values',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
};
