
<p-panel>
  <p-header>
    <p-dropdown [options]="algoList" [(ngModel)]="selectedAlgo" [filter]="true" (onChange)='handleSelectedAlgoChanged($event)' [style]='{"min-width": "300px"}'></p-dropdown>
    <p-button
      label="Download"
      icon="pi pi-download"
      iconPos="left"
      (onClick)="download(algo.fileName)"
    ></p-button>
  </p-header>
  <div class='message-container'>
    <p-messages [(value)]='messages'></p-messages>
  </div>
  <div class="image-container" *ngIf='!algo.videoUrl'>
    <img src="{{ algo.imageUrl }}" alt="{{ algo.name }}" />
  </div>
  <div class='video-container' *ngIf='algo.videoUrl'>
    <iframe width="560" height="315" [src]="algo.videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="version-container">
    <pre>
Version: {{ algo.version }}   cTrader Version: {{ algo.ctVersion }}</pre
    >
  </div>
  <div class="description-container" [innerHtml]="algo.description"></div>
  <p-table [value]="algo.parameters" *ngIf='algo.parameters'>
    <ng-template pTemplate="caption">
      Parameters
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Section</th>
        <th>Name</th>
        <th>Type</th>
        <th>Default</th>
        <th style="width: 60em;">Description</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-param>
      <tr>
        <td>{{ param.section }}</td>
        <td>{{ param.name }}</td>
        <td>{{ param.type }}</td>
        <td>{{ param.default }}</td>
        <td>{{ param.description }}</td>
      </tr>
    </ng-template>
  </p-table>
  <br />
  <br />
  <p-panel header="Comments and Feedback">
    <disqus [identifier]="algo.id"></disqus>
  </p-panel>
</p-panel>
