import { Component } from '@angular/core';

import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public items: MenuItem[] = [
    {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: 'home'},
    {label: 'Indicators', icon: 'pi pi-fw pi-chart-line', routerLink: 'indicators'},
    {label: 'About', icon: 'pi pi-fw pi-info-circle', routerLink: 'about'},
  ];

  public activeItem: MenuItem;

  constructor() {
    this.activeItem = this.items[0];
  }
}
