import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { HomeComponent } from './home/home.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { AboutComponent } from './about/about.component';
import { ViewerComponent } from './viewer/viewer.component';

export const ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'indicators', component: IndicatorsComponent },
  { path: 'about', component: AboutComponent},
  { path: 'viewer/:id', component: ViewerComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

export const ROUTING: ModuleWithProviders = RouterModule.forRoot(ROUTES);
