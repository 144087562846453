<nav class="header">
  <section class="logo-container">
    <img src="assets/images/cTKLogo.png" /> <span class="title">cTrader Kit</span>
  </section>
  <section class="menu-container">
    <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
  </section>
</nav>
<section class='content'>
  <router-outlet></router-outlet>
</section>
<footer>
  Powered by <a href="https://angular.io" title="Angular" target="_blank"><img src="/assets/images/angular-logo.png" alt="Angular Logo"> Angular</a>
  <a href="https://primefaces.org/primeng" title="PrimeNG" target="_blank"><img src="/assets/images/primeng-logo.png" alt="PrimeNG Logo"> PrimeNG</a>
  <a href="https://firebase.google.com/" title="Google Firebase" target="_blank"><img src="/assets/images/firebase-logo.png" alt="Google Firebase Logo"> Google Firebase</a>
  <a href="https://disqus.com/" title="DISQUS" target="_blank"><img src="/assets/images/disqus-logo.png" alt="DISQUS Logo"></a>
</footer>
