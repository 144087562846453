import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DisqusModule } from 'ngx-disqus';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';

import { ROUTING } from './app.routing';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { AboutComponent } from './about/about.component';
import { ViewerComponent } from './viewer/viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IndicatorsComponent,
    AboutComponent,
    ViewerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DisqusModule,
    ButtonModule,
    CardModule,
    CarouselModule,
    DataViewModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    TableModule,
    TabMenuModule,
    DisqusModule.forRoot('ctkit'),
    ROUTING,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
