<section class="about-container">
  <h1>About cTrader Kit</h1>
  <p>
    cTKit aka cTrader Kit is a set of tools developed by professional C#
    developers who also happen to trade. We developed them to make our own
    trading easier, but thought we should share them with like minded people.
  </p>

  <p>
    Why don't we just publish them on cTDN? Well we did at first but then for
    some reason the upload feature started throwing errors with no information
    to help us understand what was wrong and SpotWare were not forthcoming with
    any help. So we decided to create this site and make our cTrader Kit
    available here.
  </p>
  <p>
    If you have any questions or want to leave us some feedback use the embedded
    comments feature on the relevant page.
  </p>
</section>
