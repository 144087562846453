import { Algo } from '../algo';

export const pslAlgo: Algo = {
  id: 'psl',
  route: '/viewer/psl',
  name: 'Psych Levels',
  fileName: 'ctk Psych Levels.algo',
  imageUrl: '/assets/images/psl.png',
  shortDescription:
    'Draws lines at significant levels that often form strong S&R levels',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
  description: `Prices that end in round numbers (00, 20, 50 and 80) are considered significant and Raghee Horner among others refer to them
                Phsycological levels.  This indicator default to drawing coloured lines at these levels.  You can disable some levels and customise
                the colours and line style used.  Some consider these levels as self fullfilling prophecies because so many traders consider them
                important.  Regardless they are important and often provide strong support and resistance to price.  Default colours were chosen
                to empasise the importance of the level`,
  parameters: [
    {
      default: 'Yes',
      description:
        'Indicates whether lines for levels ending in 20 should be drawn',
      name: 'Show 20 Levels',
      section: 'Configuration',
      type: 'Boolean',
    },
    {
      default: 'Yes',
      description:
        'Indicates whether lines for levels ending in 50 should be drawn',
      name: 'Show 50 Levels',
      section: 'Configuration',
      type: 'Boolean',
    },
    {
      default: 'Yes',
      description:
        'Indicates whether lines for levels ending in 80 should be drawn',
      name: 'Show 80 Levels',
      section: 'Configuration',
      type: 'Boolean',
    },
    {
      default: 'Red',
      description:
        'The colour of lines for levels ending in 00',
      name: 'Level 00 Colour',
      section: 'Colours',
      type: 'String',
    },
    {
      default: 'Yellow',
      description:
        'The colour of lines for levels ending in 20',
      name: 'Level 20 Colour',
      section: 'Colours',
      type: 'String',
    },
    {
      default: 'Orange',
      description:
        'The colour of lines for levels ending in 50',
      name: 'Level 50 Colour',
      section: 'Colours',
      type: 'String',
    },
    {
      default: 'Yellow',
      description:
        'The colour of lines for levels ending in 80',
      name: 'Level 80 Colour',
      section: 'Colours',
      type: 'String',
    },
    {
      default: 'DotsRare',
      description:
        'The style for all lines',
      name: 'Level 00 Colour',
      section: 'Line Customisation',
      type: 'List',
    },
    {
      default: '1',
      description:
        'The thickness for lines',
      name: 'Line Thickness',
      section: 'Line Customisation',
      type: 'Integer',
    }
  ],
};
