import { Algo } from '../algo';

export const ctkitAlgo: Algo =  {
    id: 'ctkit',
    route: '/about',
    fileName: '',
    imageUrl: '/assets/images/cTKit.png',
    name: 'cTrader Kit',
    shortDescription: 'Optimise your trading with cTrader Kit',
    type: 'Page',
  };
