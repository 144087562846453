import { Component, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Algo } from '../shared/algo';
import { SelectItem } from 'primeng/api/selectitem';
import { Message } from 'primeng/api/message';
import { algos } from '../shared/algos';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent {
  public algo: Algo;
  public algoList: SelectItem[];
  public selectedAlgo: string;
  public messages: Message[] = [
    {
      severity: 'info',
      detail:
        'To install this Indicator click the Download button. Once downloading is complete Open the file. Follow the instructions from cTrader when prompted.',
    },
  ];

  constructor(
    activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private location: Location
  ) {
    const id: string = activatedRoute.snapshot.params.id;
    this.setAlgo(id);
    this.populateAlgosList();
  }

  public download(fileName: string): void {
    const link = this.document.createElement('a');
    link.href = `/assets/downloads/${fileName}`;
    link.target = '_blank';
    link.click();
  }

  public handleSelectedAlgoChanged($event: any): void {
    this.setAlgo($event.value);
    this.location.replaceState(`/viewer/${$event.value}`);
  }

  private populateAlgosList(): void {
    this.algoList = algos
      .filter((algo) => algo.type === 'Indicator')
      .map<SelectItem>((algo) => {
        return {
          label: algo.name,
          value: algo.id,
        };
      });

    this.setSelectedAlgo();
  }

  private setAlgo(id: string): void {
    this.algo = algos.find((a) => a.id === id);
  }

  private setSelectedAlgo(): void {
    this.selectedAlgo = this.algo.id;
  }
}
