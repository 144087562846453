import { Algo } from '../algo';

// tslint:disable: max-line-length
export const cshAlgo: Algo = {
  id: 'csh',
  route: '/viewer/csh',
  name: 'Currency Strength Heatmap',
  fileName: 'ctk Currency Strength Heatmap.algo',
  imageUrl: '/assets/images/csh.png',
  shortDescription:
    'Identify strenth and weakness of 8 major currencies via a heatmap',
  type: 'Indicator',
  ctVersion: '3.7',
  version: '1.1',
  description: `The Currency Strength Heatmap helps identify strong and weak currencies helping you decide what to trade.
                It subscribes to the data feed for the 28 currency pairs that are derived from the 8 major currencies.
                It derives the relatvie strength of each individual currency by calculating the average percentage move against the other 7 and plots this as a heatmap.
                Two algorithms are supported for calculating relative strength. The default is a simple average of percentage moves. The other uses a Geometric Average of the percentage moves.
                By default the heatmap is sorted with a neutral coloured row acting as the fulcrum. Stronger currencies are stacked above the fulcrum, while weaker currencies are below.
                The strongest currency is on top of the stack and the weakest at the bottom
                You can disable sorting and currencies will be presented in a matrix where colours are your only indication of strength vs weakness
                Red indicates strength while Blue indicates weakness, this applies regardless whether sorting is enabled or disabled
                The timeframe of the host chart is used and the data for the host chart is used if it is one of the 28 pairs monitored
                The indicator is updated based on the Update Frequency Seconds parameter, allowing you to tune the impact on performance.`,
  parameters: [
    {
      section: 'Configuration',
      name: 'Periods',
      type: 'Integer',
      description:
        'The number of periods(or bars) to for calculating strength',
      default: '20',
    },
    {
      section: 'Configuration',
      name: 'Use Geometric Avg.',
      type: 'Boolean',
      description:
        'Indicates whether to use the alternative algorithm for calculating strength',
      default: 'False',
    },
    {
      section: 'Configuration',
      name: 'Update Frequency Seconds',
      type: 'Integer',
      description: 'The number of seconds between updates',
      default: '2',
    },
    {
      section: 'Configuration',
      name: 'Sorted',
      type: 'Boolean',
      description:
        'Indicates whether to sort currencies into stacks with stronger ones above and weaker ones below a fulcrums',
      default: 'True',
    },
  ],
};
